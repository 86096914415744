<template>
  <b-row>
    <b-col sm="12" md="6">
      <b-card>
        <form-validation @submit="submitForm">
          <b-row>
            <b-col sm="12">
              <form-input :disabled="is_disable" type="password" label="Password Lama" :rules="{required: !is_disable}" v-model="old_password"></form-input>
            </b-col>
            <b-col sm="12">
              <form-input type="password" label="Password Baru" :rules="{required: true, confirmed: 'confirmation'}" v-model="password"></form-input>
            </b-col>
            <b-col sm="12">
              <form-input type="password" label="Ulangi Password Baru" :rules="{required: true}" vid="confirmation" v-model="confirmation"></form-input>
            </b-col>
            <b-col sm="12">
              <button-save :isloading="isloading"></button-save>
            </b-col>
          </b-row>
        </form-validation>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card v-show="cP(18)" title="Atur ulang kata sandi" class="text-center mt-3">
        <b-card-text>
          <b-button @click="renewConfirm" variant="danger" size="sm">
            <feather-icon icon="RefreshCwIcon"></feather-icon> Atur Ulang Kata Sandi
          </b-button>
          <hr>
          <small>Atur ulang kata sandi dengan menekan tombol diatas dan buat kata sandi baru.</small>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import FormValidation from '@/my-components/FormValidation.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import {BCard,BButton,BCardText} from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default {
  components:{
    FormInput,
    ButtonSave,
    FormValidation,
    BCard,
    BButton,
    BCardText
  },
  data(){
    return {
      id: this.$route.params.id,
      isloading: false,
      username:'',
      old_password: '',
      password: '',
      confirmation: '',
      is_disable: false,
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'users/changePassword',
      dispatchShow: 'users/show',
    }),
    async getData(){
      const data = await this.dispatchShow(this.$route.params.id)
      this.username = data.username
    },
    resetForm(){
      this.old_password = ''
      this.password = ''
      this.confirmation = ''
      this.is_disable = false
    },
    async submitForm(){
      this.isloading = true
      const {id,old_password,password,username,is_disable} = this
      let params = {id,old_password,username,password}
      params['is_disable'] = is_disable ? 1:0

      try {
        await this.dispatchStore(params)
        this.resetForm()
        this.toastSuccess("OK","Kata sandi berhasil dirubah")
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
    renewConfirm(){
      this.$swal({
        title:`Konfirmasi Atur Ulang Kata Sandi ?`,
        text:`Anda akan mengatur ulang kata sandi`,
        icon:`warning`,
        showCancelButton:true,
        cancelButtonText:`Tidak`,
        confirmButtonText:`Ya`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if(result.value) {
          this.is_disable = true
        }
      })
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>